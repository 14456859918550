<div class="kanban-outer">
  <ejs-kanban [showEmptyColumn]="kanbanSettings.showEmptyColumn" [allowDragAndDrop]="kanbanSettings.allowDragAndDrop"
    keyField="column" [dataSource]="cardItems" [cardSettings]="cardSettings" (dialogOpen)="dialogOpen($event)">
    <e-columns>
      <e-column [keyField]="columns.recent" [showItemCount]="false"
        [headerText]="resources.localisedStrings.recentColumnTitle">
      </e-column>
      <e-column [keyField]="columns.import" [showItemCount]="false"
        [headerText]="resources.localisedStrings.importColumnTitle">
      </e-column>
      <e-column [keyField]="columns.create" [showItemCount]="false"
        [headerText]="resources.localisedStrings.createColumnTitle">
      </e-column>
      <e-column [keyField]="columns.review" [showItemCount]="false"
        [headerText]="resources.localisedStrings.reviewColumnTitle">
      </e-column>
      <e-column [keyField]="columns.translate" [showItemCount]="false"
        [headerText]="resources.localisedStrings.translateColumnTitle">
      </e-column>
      <e-column [keyField]="columns.publish" [showItemCount]="false"
        [headerText]="resources.localisedStrings.publishColumnTitle">
      </e-column>
    </e-columns>
    <ng-template #cardSettingsTemplate let-data>
      <div class="e-card-content {{data.cardClass}}">
        <h5>{{data.title}}</h5>
        <h6 *ngIf="data.subtitle">{{resources.localisedStrings[data.subtitle]}}</h6>
        <p>{{data.summary}}</p>
        <div>
          <a [href]="data.url || '#'" (click)="onCardLinkClick($event, data.url)" ejs-button cssClass="e-link cardLink">
            <span>{{data.buttonText}}</span>
            <app-icon [iconName]="data.buttonIcon"></app-icon>
          </a>
        </div>
      </div>
    </ng-template>
  </ejs-kanban>
</div>