import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { filter, Subscription } from 'rxjs';
import { DialogSettingsModel } from 'src/app/models/dialog-settings.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MessengerService } from 'src/app/services/messenger/messenger.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { ReviewService, ReviewType } from 'src/app/services/review/review.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UtilService } from 'src/app/services/util/util.service';
import { SharedDialogComponent } from 'src/app/components/shared/dialog/dialog.component';
import { ConfigService } from 'src/app/services/config/config.service';
import { ReleaseStateModel } from 'src/app/models/release-state.model';

@Component({
  selector: 'app-complete-review-dialog',
  templateUrl: './complete-review-dialog.component.html',
  styleUrls: ['../../shared/dialog/dialog.component.scss', './complete-review-dialog.component.scss']
})
export class CompleteReviewDialogComponent implements OnInit, OnDestroy {
  /**
   * Reference to the dialog for show/hide functions
   */
  @ViewChild('completeReviewDialog') dialog: SharedDialogComponent;
  private messengerServiceSubscription: Subscription;
  // dialog settings
  public dialogSettings: DialogSettingsModel = {
    isModal: true,
    visible: false,
    showCloseIcon: false,
  };

  // review data from api
  private review: any;
  private bookToc: any;
  public releaseStates: ReleaseStateModel[] = [];

  public isCollaborationReview: boolean = null;
  // dialog data
  public data = {};
  public disableComplete: boolean = false;

  // calculated data
  private reviewNodes = [];
  private rejectedNodes = [];
  private approvedNodes = [];
  private otherNodes = [];
  private otherStates: string[] = [];
  private topicCount = 0;

  constructor(
    private reviewService: ReviewService,
    public resources: ResourceService,
    private messengerService: MessengerService,
    private toastService: ToastService,
    private logger: LoggerService,
    private utils: UtilService,
    public authService: AuthenticationService,
    private configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.messengerServiceSubscription = this.messengerService
      .getMessageListener()
      .pipe(filter((mess) => (
        mess?.origin && mess?.message
        && mess.origin === 'contribute'
        && mess.message === 'book/showCompleteReview'
      )))
      .subscribe((mess) => {
        this.messageReceived(mess.data);
      });
  }

  /**
   * Handle complete review messages received and load collab or approval review data
   * @param data message data received
   */
  public messageReceived(data: any) {
    if (typeof data !== 'object' || !data?.reviewId || !data?.reviewTypeId) {
      this.toastService.showToast(this.resources.localisedStrings.error, this.resources.localisedStrings.completeApprovalDialogGetDataError);
      this.logger.error('unable to load complete review dialog data, missing required information');
      return;
    }

    this.isCollaborationReview = parseInt(data.reviewTypeId) === ReviewType.Collaboration;
    if (this.isCollaborationReview) {
      this.loadCollaborationReviewData(data.reviewId, data.reviewTypeId);
    }
    else {
      this.loadApprovalReviewData(data.reviewId.toString());
    }
  }

  /**
   * Load collaboration review data, and show dialog
   * @param reviewId id of the review to complete
   * @param reviewTypeId type of the review
   */
  private loadCollaborationReviewData(reviewId, reviewTypeId) {
    // technical/collaboration review just has text and complete/cancel
    this.data = { dialogTitle: this.resources.localisedStrings.completeReviewDialogTitle };
    // set required review data, needed for completing the review
    this.review = { reviewId: reviewId, reviewType: reviewTypeId };
    this.disableComplete = false;

    this.showDialog();
  }

  /**
   * Load approval review data, and show dialog
   * @param reviewId id of the review to complete
   */
  private async loadApprovalReviewData(reviewId: string) {
    // get review data from api.
    const [review, releaseStates] = await Promise.all([
      this.reviewService.getReview(reviewId),
      this.reviewService.getReleaseStates(),
    ]);

    if(!review?.success || !review?.data?.status || !releaseStates.success) {
      // show an error and dont show modal, if getting data failed
      this.toastService.showToast(this.resources.localisedStrings.error, this.resources.localisedStrings.completeApprovalDialogGetDataError);
      this.logger.error('unable to load complete review dialog data, missing required information');
      return;
    }

    // must get review data first before having access to reviewState
    const bookToc = await this.reviewService.getResolvedBookToc(reviewId, review.data.status);

    if(!bookToc.success) {
      // show an error and dont show modal, if getting data failed
      const msg = this.resources.getString('completeApprovalDialogGetDataError');
      this.toastService.showToast(this.resources.localisedStrings.error, this.resources.localisedStrings.completeApprovalDialogGetDataError);
      this.logger.error('unable to load complete review dialog data, missing required information');
      return;
    }

    // assign data to component.
    this.review = review.data;
    this.bookToc = bookToc.data;
    this.releaseStates = releaseStates.data;

    this.topicCount = 0;
    this.topicCount = await this.summarizeBookToc(this.bookToc);

    const isEditor: boolean = !!this.review.authorizedEditors.find((x) => x.id === this.authService.getUserProperty('userId'));
    const now = new Date();

    this.data = {
      dialogTitle: this.resources.localisedStrings.completeApprovalDialogTitle,
      bookName: this.review.bookName,
      startDate: moment(this.review.startDate).format(this.configService.default.shortDateFormat),
      startDateLabel: (new Date(this.review.startDate) < now ? this.resources.localisedStrings.startedOn : this.resources.localisedStrings.startingOn),
      endDate: moment(this.review.endDate).format(this.configService.default.shortDateFormat),
      endDateLabel: (new Date(this.review.endDate) < now ? this.resources.localisedStrings.endedOn : this.resources.localisedStrings.endingOn),
      summaryText: this.utils.formatString(this.resources.localisedStrings.completeApprovalDialogDescription, {
        totalNumTopics: this.topicCount,
        bookName: this.review.bookName,
      }),
      completeReviewApproved: this.utils.formatString(this.resources.localisedStrings.completeApprovalDialogApproved, {
        numTopics: this.approvedNodes.length,
        stateName: this.review['nextStateText'],
      }),
      completeReviewToBeApproved: this.utils.formatString(this.resources.localisedStrings.completeApprovalDialogToBeApproved, {
        numTopics: this.reviewNodes.length,
        stateName: this.review['reviewStateText'],
      }),
      completeReviewRejected: this.utils.formatString(this.resources.localisedStrings.completeApprovalDialogRejected, {
        numTopics: this.rejectedNodes.length,
        stateName: this.review['editorialStateText'],
      }),
      completeReviewOther: this.utils.formatString(this.resources.localisedStrings.completeApprovalDialogOther, {
        numTopics: this.otherNodes.length,
        stateName: this.otherStates.join(', '),
      }),
      isEditor: isEditor,
      checkLabel: this.utils.formatString(this.resources.localisedStrings.completeApprovalDialogApproveTopicsCheckbox, {
        numTopics: this.reviewNodes.length,
        stateName: this.review['reviewStateText']
      }),
      showCheck: (isEditor && this.reviewNodes.length > 0),
      checked: (isEditor && this.reviewNodes.length > 0),
    };

    this.validateCanComplete();
    this.showDialog();
  }

  /**
   * Summarize and count the toc data to be displayed
   * @param bookToc toc to summarize
   * @returns total number of topics
   */
  private async summarizeBookToc(bookToc) {
    if (!bookToc) {
      return this.topicCount;
    }

    bookToc.forEach(async (item) => {
      // all book toc items, and child items are camelCased
      if(item?.releaseStateId || item?.releaseStateId > -1) {
        this.topicCount++;

        switch (item.releaseStateId) {
        // approved
        case this.review.nextStateId:
          this.approvedNodes.push(item);
          break;

        // waiting to be approved
        case this.review.reviewStateId:
          this.reviewNodes.push(item);
          break;

        // rejected
        case this.review.editorialStateId:
          this.rejectedNodes.push(item);
          break;

        default:
          this.otherNodes.push(item);
          // get the configured name of the release State from the release state id
          const releaseState = this.getReleaseStateName(item.releaseStateId);
          // add release state name to the displayed list of other release states
          if (releaseState && !this.otherStates.includes(releaseState)) {
            this.otherStates.push(releaseState);
          }
          break;
        }
        this.topicCount += await this.summarizeBookToc(item.children);
      }
    });
    return this.topicCount;
  }

  /**
   * Get release state name using the passed in id
   * @param id release state id to search
   * @returns name of the release state
   */
  private getReleaseStateName(id): string {
    return this.releaseStates.find((rs) => rs.releaseStateId == id)?.name;
  }

  /**
   * Check if the approval review can be completed, and enable/disable complete button with result
   * Runs to initially validate the data before showing to the user, and again on click of check-approve checkbox
   */
  validateCanComplete() {
    if (this.isCollaborationReview) {
      return;
    }
    var forceApprove = this.data['checked'] && this.data['isEditor'];

    // do not allow completion if any rejected or other nodes present, or if nodes waiting on review are present and approve is not checked
    this.disableComplete = this.rejectedNodes.length > 0 || this.otherNodes.length > 0 || (this.reviewNodes.length > 0 && !forceApprove);
  }

  /**
   * Complete a review, show toast if error, or show toast and close dialog if success
   */
  public async completeReview() {
    const result = await this.reviewService.completeReview(this.review.reviewId, this.review.reviewType);

    if (!result?.success) {
      let msg = this.resources.getString(result.reason);
      if (!result.reason || msg === result.reason) {
        msg = this.resources.getString('completeReviewError');
      }
      this.toastService.showToast(this.resources.localisedStrings.error, msg);
      this.logger.error('unable to complete the review');
      return;
    }

    // this.toastService.showToast(
    //   this.isCollaborationReview ? this.resources.localisedStrings.reviewCompletedTitle : this.resources.localisedStrings.approvalCompletedTitle,
    //   this.isCollaborationReview ? this.resources.localisedStrings.reviewCompletedDescription : this.resources.localisedStrings.approvalCompletedDescription);
    this.hideDialog();
  }

  /**
   * Clear data and hide the dialog
   */
  public hideDialog() {
    this.dialog.hide();
    setTimeout(() => {
      this.clearDialogData();
    }, this.configService.default.dialogAnimationDuration);
  }

  /**
   * Show the dialog
   */
  public showDialog() {
    this.dialog.show();
  }

  /**
   * Clear all loaded in data for this review
   */
  private clearDialogData() {
    this.review = undefined;
    this.bookToc = undefined;
    this.releaseStates = [];
    this.isCollaborationReview = undefined;
    this.data = {};
    this.disableComplete = false;

    // calculated data
    this.reviewNodes = [];
    this.rejectedNodes = [];
    this.approvedNodes = [];
    this.otherNodes = [];
    this.otherStates = [];
    this.topicCount = 0;
  }

  ngOnDestroy() {
    this.messengerServiceSubscription.unsubscribe();
  }

}
